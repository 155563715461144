.order-t1 td:nth-child(3){
    text-align: right !important;
}
.order-t1 th:nth-child(3){
    text-align: right !important;
}

.order-t1 td:nth-child(5){
    text-align: right;
}
.order-t1 th:nth-child(5){
    text-align: right;
}


.ordet-back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

h5.detmain-h5 {
    margin-left: 12%;
    font-size: 20px;
    font-weight: 600;
}

.orderdet-fdiv {
    margin-top: 35px;
    background: white;
    border: 1px solid #dddddd;
    overflow: hidden;
    width: 76%;
    display: block;
    margin: 30px auto;
    border-radius: 10px;
}

.orderdet-fdiv1 {
    padding: 20px;
}
.total-container {
    color: rgb(14, 14, 14);
    max-width: 350px;
    margin-left: auto;
    margin-top: 20px;
    box-shadow: 0px 0px 10px #80808030;
    padding: 20px;
    border-radius: 0;
    font-size: 14px;
    margin-right: 1.5%;
    margin-bottom: 2.5%;
}
.total-row {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    color: #000;
}
span.total-label1 {
    font-weight: bold;
}
.orderdet td:nth-child(5){
    text-align: right;
}
.orderdet th:nth-child(5){
    text-align: right;
}
.orderdet th{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    background-color: #f3f3f3;
}
.orderdet{
    padding: 0px;
    margin: 0 auto;
    border-radius: 10px;
    border-radius: 10px !important;
    box-shadow: 0px 0px 2px #0000002b;
    overflow: hidden;
    width: 97%;
}
/* .orderdet1{
    margin-bottom: 100px;
} */
.orderdet1 th{
    border: none;
    font-weight: 400;
}
.orderdet1 td{
    border: none;
    font-weight: bold;
}
.orderdet-fdiv2 {
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-top: 12px;
}
span.order-span {
    font-size: 15px;
    font-weight: bold;
}
.orderspan{
    margin-bottom: 100px;
}