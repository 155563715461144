body {
    font-family: 'Poppins', sans-serif;
    background-color: #f5f5f5;
}

header.header-nav {
    background-color: #FFF;
    text-align: center;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    border-top: 5px solid #181717;
    padding-block: 12px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    padding: 15px 65px 15px 30px;
}

.nav-text-logo {
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    color: #181717;
}

.uni-login-form {
    padding: 0px 0px 40px;
}

.uni-login-form-box {
    background-color: #FFF;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
    text-align: center;
    width: 620px;
    margin: 60px auto;
}

.uni-login-form-box .login-heading-image img {
    width: 60px;
    margin: 0 auto;
    margin-bottom: 7px;
}

.uni-login-form-box .login-input-bx {
    margin-bottom: 25px;
}

.uni-login-form-box .login-input-bx label {
    text-align: left;
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #222222;
}

.uni-login-form-box .login-input-bx span {
    position: relative;
    display: block;
}

.uni-login-form-box .login-input-bx span img {
    position: absolute;
    width: 25px;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
}

.uni-login-form-box .login-input-bx input {
    width: 100%;
    text-align: left;
    padding: 15px 15px 15px 50px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #d6d9dd;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif !important;
}

.error {
    color: red !important;
}

.uni-login-form-box .minus-margin {
    margin-bottom: 30px;
}

.remember-forgiot {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.remember-forgiot .rememberr label {
    text-align: left;
    display: block;
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    color: #222222;
    display: flex;
    gap: 10px;
}

.subbtn button {
    background-color: #181717;
    border: 0;
    padding: 16px 35px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 10px;
    color: #fff;
    margin-top: 28px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-inline: auto;
    cursor: pointer;
    font-family: 'Poppins', sans-serif !important;
}

.dropdown {
    position: relative;
    display: inline-block;
    min-width: 150px;
}

.user-login img {
    width: 30px;
    border-radius: 50px;
    padding: 5px;
    object-fit: scale-down;
}

button.user-login.dropbtn span {
    font-size: 18px;
}

.material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.material-symbols-outlined {
    font-family: 'Material Symbols Outlined' !important;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #FFF;
    min-width: 185px;
    overflow: auto;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-radius: 5px;
    left: 0px;
    top: 35px;
}

.dropdown-content a {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: start;
    font-size: 12px;
    font-weight: 400;
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
}

.dropdown-content a img {
    width: 17px;
    margin-right: 10px;
}


section#storeslist {
    min-height: calc(100vh - 194px);
}

section#storeslist {
    margin-top: 30px;
}

.stores-list-sec {
    max-width: 1450px;
    margin: 0 auto;
}

.table_header_sec_store_list {
    display: flex;
    justify-content: space-between;
    max-width: 1450px;
    margin: 0 auto 15px;
    align-items: center;
}

.table_header_sec_store_list h1,
.table_header_sec h1 {
    font-size: 20px;
    line-height: 30px;
    color: #222222;
    font-weight: 600;
}

.filter_btn {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    min-width: 350px;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.18rem + 2px);
    padding: 0.59rem 1rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search_bar {
    max-width: 650px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
}

.search_bar .input-group {
    margin-bottom: 0px !important;
}

.Store_table {
    overflow: auto;
}

table.table.table-div1.table-div2 {
    width: 1450px;
    margin-bottom: 1rem;
    color: #8a909d;
    background: white;
    max-width: 1450px;
    margin: 0 auto;
    border-radius: 10px 10px 0px 0px;
    overflow: auto;
}

table.table.table-div1.table-div2 thead th,
tbody tr td:first-child {
    font-size: 14px;
    font-weight: 600;
    color: #3b3b3b;
    padding: 0.5rem 1.2rem;
}

table.table.table-div1.table-div2 td {
    vertical-align: middle;
    border-top: 1px solid #e5e9f2;
    font-size: 14px;
    font-weight: 400;
    color: #3b3b3b;
    padding: 0.5rem 1.2rem;
    height: 45px;
}

.pagination-section {
    max-width: 100%;
    /* padding: 20px; */
    margin: 0 auto 30px;
    background: white;
    border: 1px solid #dddddd;
    /* border-top: 1px solid #e5e9f2; */
    /* display: flex;
    justify-content: start; */
    align-items: center;
    border-top: none;
    gap: 30px;
    border-radius: 0px 0px 10px 10px;
    /* border-bottom: 1px solid #f1f1f1; */
    /* box-shadow: 0px 1px 2px #0000002b; */
}

ul.pagination {
    padding-top: 10px;
}

.pro-tab td:nth-child(4) {
    text-align: right;
}

.pro-tab th:nth-child(4) {
    text-align: right;
}
.dropdown-menu{
    cursor: pointer;
}
.logout{
    height: 20px;
    padding-left: 10px;
}
table.table.table-div1.table-div2 tr td:nth-child(5) {
    text-align: end;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 175px;
    height: 50px;
    border: none;
}

table.table.table-div1.table-div2 tr td:nth-child(5) a {
    text-decoration: none;
}

.table-div2 td button {
    background-color: #181717;
    border: 0;
    padding: 8px 14px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    /* margin-left: auto; */
    box-shadow: 0px 1px 0px #181717;
    height: 35px;
}

.search_bar span.material-symbols-outlined {
    margin-right: 0;
    position: absolute;
    right: 4px;
    color: #181717;
    font-weight: 600;
}

.form-outline {
    display: flex;
    align-items: center;
}

input#search-focus {
    color: #3b3b3b;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    background: white;
    border: 1px solid #d6d9dd;
    border-radius: 5px;
    display: flex;
    gap: 10px;
    min-width: 360px;
    height: 41px;
}

.active>.page-link,
.page-link.active {
    background-color: #181717 !important;
    border-color: #181717 !important;
    color: white !important;
}

.pagination .page-link {
    padding: 5px 14px !important;
    color: #8a909d;
}

i.fas.fa-box-full {
    font-size: 15px;
    display: flex;
    align-items: center;
}

i.fas.fa-eye {
    font-size: 18px;
}

i.fas.fa-search {
    font-size: 15px;
}

i.far.fa-shopping-bag {
    font-size: 15px;
    display: flex;
    align-items: center;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active,
.btn:hover {
    border: none;
}

input[type="checkbox"] {
    height: 20px;
    width: 20px;
}

.logimg {
    top: 28px !important;
}


.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #181717;
}

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.pro-div {
    display: flex;
    justify-content: space-between;
}

.pro-div .pagin {
    padding: 15px;
    font-size: 15px;
}

.store-fdiv {
    /* margin-top: 35px; */
    background: white;
    /* border-radius: 10px 10px 0px 0px; */
    border: 1px solid #dddddd;
    overflow: hidden;
    width: 100%;
    display: block;
    border: none;
    /* border-bottom: none; */
}

.pro-sear input {
    margin-right: 5%;
    margin-top: 40px;
}

.pro-sear {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.view-pig {
    max-width: 100%;
    padding: 20px;
    margin: 0 auto 30px;
    background: white;
    border: 1px solid #dddddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    border-radius: 0px 0px 10px 10px;
}

button.pro-back {
    background-color: #181717;
    border: 0;
    padding: 8px 13px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 5px;
    color: #fff;
    height: 35px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 12%;
    /* margin-inline: auto; */
    cursor: pointer;
    font-family: 'Poppins', sans-serif !important;
}

.pro-bacdiv {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.pro-tab {
    width: 100% !important;
    border: none !important;
    border-radius: unset !important;
    margin-top: unset !important;
}

.pro-sear .main-h5 {
    margin-left: 12%;
}

.pro-fdiv {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: block;
    /* margin: 30px auto; */
    overflow: hidden;
    width: 100%;
}

.sto-ser {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.propa-fdiv {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: block;
    margin: 30px auto;
    overflow: hidden;
    width: 76%;
}

.pr1 {
    margin-right: 12% !important;
}

.templo div {
    padding: 10px 0;
}

.templo>div>div {
    width: 21% !important;
}

h1.modal-h1 {
    text-align: center;
    font-size: 20px;
}
.modal-right-section {
    text-align: center;
    margin-top: 20px;
}
button.mclosebtn {
    margin: 0px 5px;
}
.apro-abtn{
    background-color: #181717;
    border: 0;
    padding: 8px 14px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    color: #fff;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    /* margin-left: auto; */
    box-shadow: 0px 1px 0px #181717;
    height: 35px;
}
.apro-cbtn {
    border: 1px solid #ced4da;
    height: 35px;
    padding: 8px 14px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
}