
.order-tab td:nth-child(4){
    text-align: right;
}
.order-tab th:nth-child(4){
    text-align: right;
}
.dataTables_length select.form-select.form-select-sm {
    height: 38px;
}
.dataTables_length label {
    display: flex;
    gap: 10px;
    align-items: center;
}

.ord-ser {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 360px;
    justify-content: end;
}



/* 
a.page-link span {
    border: 1px solid gray;
    background-color: #ddd;
    border-radius: 6px 0px 0px 6px;
    color: #706c6c;
} */
.page-item:first-child .page-link {
    border: 1px solid gray;
    background-color: #ddd !important;
    border-radius: 6px 0px 0px 6px;
    color: #706c6c; 
}

.ord-back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}
/* .order-tab table th:first-child {
    text-align: center;
}
.order-tab table td:first-child {
    text-align: center;
} */

.input-group input.form-control {
    border-left: none;
}
.order-fdiv{
    margin-top: 35px;
    background: white;
    /* border-radius: 10px; */
    border: 1px solid #dddddd;
    overflow: hidden;
    width: 76%;
    display: block;
    margin: 30px auto;
    border-radius: 10px;
}
span#basic-addon1 {
    background-color: transparent;
    width: 38px !important;
    height: 42px;
}
.order-fdiv1input {
    display: flex;
    justify-content: space-between;

}
.order-fdiv1dow {
    display: flex;
    gap: 10px;
    align-items: center;
}
.order-fdiv1dow span {
    color: #3B3B3B;
    cursor: pointer;
}
.order-fdiv1dow i {
    color: #3B3B3B;
    cursor: pointer;
}
.order-fdiv1filter {
    display: flex;
    align-items: center;
    gap: 10px;
}
.order-fdiv1filter span {
    color: #3B3B3B;
}
.order-fdiv1filter i {
    color: #3B3B3B;
}
.order-fdiv1 {
 padding: 20px;
}
.order-fdiv1input .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 82%;
    margin-left: 0%;
    align-items: center;
}
.table-div .table>:not(caption)>*>* {
    padding: 0.5rem 1.2rem;
    color: #3B3B3B;
    font-size: 14px;
}
.active>.page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #d6dadf !important;
    border-color: gray !important;
    color: gray !important;
    border-radius: 3px
}
li.page-item.disabled {
    box-shadow: none !important;
}
h5.main-h5 {
    margin-left: 12%;
    font-size: 20px;
    font-weight: 600;
}
.pag-div i{
    color: gray;
}
/* .table-div{
    width: 100%;
    overflow: scroll;
} */




i.page-item.disabled {
    border-radius: 2px 0px 0px 2px !important;
    margin-right: 14px;
    margin-left: 5px !important;
}

ul.pagination {
    /* gap: 10px !important;
    display: flex !important; */
    align-items: center;
}

/* .page-item:first-child .page-link {
    border: none;
    padding: 0;
    background: transparent;    
} */
.page-link:focus {
    box-shadow: none !important;
}
.page-item:last-child .page-link {
    border: 1px solid #ddd;
    margin-right: 10px;
    border-radius: 4px;
    background: #ddd;
    border: 1px solid gray;
}


i.fa.fa-chevron-left {
    border: 1px solid gray;
    padding: 10px;
    margin-right: 10px;
    border-radius: 4px;
    background: #ddd;

}

li.page-item.disabled a {
    background: transparent !important;
    border: none;
    padding: 0;
}

table.table.table-bordered {
    margin: 0;
}

.order-td {
    color: #6db248 !important;
    text-decoration: underline;
}


@media screen and (max-width: 768px) {
    .table-div{
        width: 100%;
        overflow: scroll;
    }


    .order-fdiv1 {
        display: flex;
        justify-content: end;
        padding: 15px 18px;
        flex-direction: column;
    }
    .order-fdiv1input {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .order-fdiv1input .input-group {
        width: 100%;
        
    }

    ul.pagination {
        gap: 10px !important;
        display: flex !important;
        justify-content: center;
        margin-top: 20px;
    }
  }

    
  @media (min-width: 768px) and (max-width: 1200px) {

    .order-fdiv1input {
      display: flex;
      gap: 14px;
      width: 60%;
  }
  
  
    }

  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .table-div{
        width: 100%;
        overflow: scroll;
    }
  }

